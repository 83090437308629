<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row">
      <div class="col-6">
        <div v-if="!readOnly">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add-doc-bacheca"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi comunicazione
          </button>
          <AddDocBacheca @documentAdded="$emit('getBachecaList')" />
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          comunicazioni di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        bacheca_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Non sono presenti comunicazioni.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="bacheca_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsBacheca"
        @current-change="setCurrentPageBacheca"
      >
        <template v-slot:cell-img_doc="{ row: data }">
          <!-- <img :src="data.img_doc" style="width: 2rem"/> -->
          <!-- <PreviewUploadedFile
            :name="data.fname"
            tag="div"
            :noText="true"
            @downloadFile="downloadDocument(data.item_id, data.fname)"
          /> -->
          <PreviewDaEstensione
            :estensione="
              data.fname.substring(
                data.fname.lastIndexOf('.') + 1,
                data.fname.length
              )
            "
            @imageClick="downloadDocument(data.item_id, data.fname)"
          />
        </template>
        <template v-slot:cell-tipo_documento="{ row: data }"
          >{{ data.tipo_documento }}
        </template>
        <template v-slot:cell-titolo="{ row: data }">
          <div class="tab-long">{{ data.titolo }}</div>
        </template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long">
            {{ data.descrizione }}
            <!-- <br />{{ data.sommario }} <br />
            [{{ data.province }}] -->
          </div>
        </template>
        <template v-slot:cell-data="{ row: data }"
          ><div class="text-center">
            {{ useFormatDate(data.data) }}
          </div>
        </template>
        <template v-slot:cell-diritti_comitato="{ row: data }"
          ><div class="text-center">
            <input
              v-model="data.diritti_comitato"
              type="checkbox"
              id="scales"
              name=""
              :checked="data.visibile_a.includes('3')"
              disabled
            /></div
        ></template>
        <template v-slot:cell-diritti_societa="{ row: data }"
          ><div class="text-center">
            <input
              v-model="data.diritti_societa"
              type="checkbox"
              id="scales"
              name=""
              :checked="data.visibile_a.includes('5')"
              disabled
            /></div
        ></template>
        <!-- <template v-slot:cell-diritti_DIR="{ row: data }"
          ><div class="text-center">
            <input
              v-model="data.diritti_DIR"
              type="checkbox"
              id="scales"
              name=""
              disabled
            /></div
        ></template> -->
        <template v-slot:cell-diritti_TEC="{ row: data }"
          ><div class="text-center">
            <input
              v-model="data.diritti_TEC"
              type="checkbox"
              id="scales"
              name=""
              :checked="data.visibile_a.includes('91')"
              disabled
            /></div
        ></template>
        <!-- <template v-slot:cell-diritti_UDG="{ row: data }"
          ><div class="text-center">
            <input
              v-model="data.diritti_UDG"
              type="checkbox"
              id="scales"
              name=""
              disabled
            /></div
        ></template> -->
        <template v-slot:cell-options="{ row: data }"
          >{{ data.options }}
          <div
            class="btn-group"
            @click="selectedDocId = data.item_id"
            v-if="!readOnly"
          >
            <button class="btn btn-sm dropdown p-1">
              <a
                href="#"
                class="navi-link"
                data-bs-toggle="modal"
                data-bs-target="#modal_detail-doc-bacheca"
              >
                <i class="bi bi-pen text-dark fs-5"></i>
              </a>
            </button>
            <button class="btn btn-sm dropdown p-1" @click="eliminaFile">
              <i class="bi bi-trash text-danger fs-5"></i>
            </button></div
        ></template>
      </Datatable>
    </div>
    <DocBachecaDetail
      :selectedId="selectedDocId"
      @documentoEdited="
        $emit('getBachecaList');
        selectedDocId = null;
      "
      @resetIdDoc="selectedDocId = null"
      :disabled="disabled"
    />
    <br />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import AddDocBacheca from "./AddDocBacheca.vue";
import DocBachecaDetail from "./DocBachecaDetail.vue";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import { useFormatDate } from "@/composables/formatDate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { deleteDocumento } from "@/requests/documentiBacheca";
import { downloadElement } from "@/requests/downloadFiles";
import PreviewDaEstensione from "@/components/components-fit/utility/PreviewDaEstensione.vue";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "TableBacheca",
  components: {
    AddDocBacheca,
    DocBachecaDetail,
    Datatable,
    Loading,
    PreviewDaEstensione,
  },
  emits: ["getBachecaList", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsBacheca = (e) => {
      store.commit("setFetchRowsBacheca", e);
      emit("getBachecaList");
    };
    const setCurrentPageBacheca = (page) => {
      store.commit("setCurrentPageBacheca", page);
      emit("getBachecaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnBacheca", columnName);
      store.commit("setSortOrderBacheca", order);
      emit("getBachecaList");
    };

    const tableHeader = ref([
      {
        key: "img_doc",
        sortable: false,
      },
      {
        name: "Tipo",
        key: "tipo_documento",
      },
      {
        name: "Oggetto",
        key: "titolo",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Data",
        key: "data",
        align: "center",
      },
      {
        name: "Comitato",
        key: "diritti_comitato",
        sortable: false,
      },
      {
        name: "Società",
        key: "diritti_societa",
        sortable: false,
      },
      // {
      //   name: "DIR",
      //   key: "diritti_DIR",
      // },
      {
        name: "TEC",
        key: "diritti_TEC",
        sortable: false,
      },
      // {
      //   name: "UDG",
      //   key: "diritti_UDG",
      // },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const disabled = ref(false);
    const selectedDocId = ref(false);

    const downloadingFile = ref(false);
    const downloadDocument = async (id, estensione) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(id, "bacheca", estensione);
        // .then((res) => {
        //
        //   if (res.status != 200) {
        //     alertFailed(
        //       res.data.message
        //         ? res.data.message
        //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        //     );
        //     return;
        //   }
        // });
        downloadingFile.value = false;
      }
    };

    const isLoading = ref(false);

    const eliminaFile = (idDoc) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteDocumento(selectedDocId.value).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              emit("getBachecaList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    return {
      bacheca_list: computed(() =>
        store.getters.getStateFromName("resultsbacheca_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedbacheca_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordbacheca_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusbacheca_list")
      ),

      currentPage: computed(() => store.getters.currentPageBacheca),
      rowsToSkip: computed(() => store.getters.rowsToSkipBacheca),
      fetchRows: computed(() => store.getters.fetchRowsBacheca),
      sortColumn: computed(() => store.getters.sortColumnBacheca),
      sortOrder: computed(() => store.getters.sortOrderBacheca),
      setFetchRowsBacheca,
      setCurrentPageBacheca,
      setSortOrderColumn,
      tableHeader,
      disabled,
      selectedDocId,
      useFormatDate,
      downloadDocument,
      eliminaFile,
      isLoading,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style></style>
