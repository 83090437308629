<template>
  <div class="pb-3">
    <h1>FITP INFORMA</h1>
  </div>
  <div>
    <TableBacheca
      @getBachecaList="getBachecaList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  //watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableBacheca from "@/components/components-fit/bacheca/TableBacheca.vue";
import { useMsal } from "@/composition-api/useMsal";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "bacheca",
  components: {
    TableBacheca,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Bacheca", []);
    });
    const store = useStore();
    const { accounts } = useMsal();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipBacheca);
    const fetchRows = computed(() => store.getters.fetchRowsBacheca);
    const sortColumn = computed(() => store.getters.sortColumnBacheca);
    const sortOrder = computed(() => store.getters.sortOrderBacheca);

    const getBachecaList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.BACHECA_LIST,
        itemName: "bacheca_list",
      });
    };

    /*  const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getBachecaList();
    }); */

    const resetFilters = () => {
      store.commit("resetFiltersBacheca");
      getBachecaList();
    };
    getBachecaList();
    const router = useRouter();
    const puoAccedereBacheca = computed(() => localStorage.getItem("canAB"));
    if (puoAccedereBacheca.value == "false") router.push("/bacheca-public");
    return {
      getBachecaList,
      resetFilters,
      accounts,
    };
  },
});
</script>
