<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="modal_detail-doc-bacheca"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content" v-if="documento.item_id">
          <div class="modal-header">
            <h5 class="modal-title">Dettaglio comunicazione</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div
                class="mx-auto my-auto text-center py-5 my-5"
                v-if="isLoadingDetail"
              >
                <div class="spinner-border text-gray-600" role="status"></div>
                <span class="text-gray-600 ps-3 fs-2">Loading</span>
              </div>
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold">Tipo comunicazione</label>
                    <SelectInput
                      :options="bacheca_tipo_documenti"
                      name="id_tipo"
                      :value="documento.id_tipo_docum_bacheca"
                      @changeSelect="documento.id_tipo_docum_bacheca = $event"
                    />
                  </div>
                  <div class="col-md-9">
                    <label class="fw-bold">Oggetto</label>
                    <Field
                      name="titolo"
                      type="text"
                      class="form-control"
                      :disabled="disabled"
                      v-model="documento.titolo"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="titolo"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold">Sommario comunicazione</label>

                    <Field
                      name="descrizione"
                      type="text"
                      as="textarea"
                      class="form-control"
                      :disabled="disabled"
                      v-model="documento.descrizione"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="descrizione"
                    />
                  </div>
                </div>
                <div class="row" v-show="!disabled">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">File</label>
                  </div>

                  <div class="col-lg-11 d-flex ps-0 pe-xl-11">
                    <div
                      class="image-input image-input-outline ms-2"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    ></div>
                  </div>
                  <DropZone
                    @files-dropped="addFile($event, supportedFormatBacheca)"
                    #default="{ dropZoneActive }"
                  >
                    <label for="file-input-edit-bac" style="cursor: pointer">
                      <span v-if="dropZoneActive">
                        <span>Lascia qui il file </span>
                        <span class="smaller">per aggiungerlo</span>
                      </span>
                      <span v-else>
                        <span>Trascina qui il tuo file</span>
                        <span class="smaller">
                          oppure
                          <strong><em>clicca qui</em></strong>
                          per selezionare il file
                        </span>
                      </span>
                    </label>
                    <input
                      type="file"
                      id="file-input-edit-bac"
                      style="display: none"
                      :accept="supportedFormatBacheca"
                      @change="onInputChange"
                    />
                    <ul class="ps-0" v-if="file.id">
                      <FilePreview
                        :key="file.id"
                        :file="file"
                        tag="li"
                        @remove="removeFile"
                      />
                    </ul>
                  </DropZone>
                </div>
                <div class="row">
                  <div class="col-lg-11 form-text">
                    Tipi di file concessi: {{ supportedFormatBacheca }}
                  </div>
                </div>
                <div class="image-list" style="margin-top: 2.5rem">
                  <PreviewUploadedFile
                    :name="documento.fname"
                    tag="div"
                    @downloadFile="downloadDocumento"
                    :noText="true"
                  />
                </div>

                <hr class="my-10" />

                <div class="row">
                  <div class="col-md-4">
                    <p class="">Diritti di accesso figure:</p>
                  </div>

                  <div class="col-md-8">
                    <div class="row">
                      <div
                        class="col-md-4 d-flex"
                        v-for="utenza in bacheca_tipo_utenza"
                        :key="utenza.id"
                      >
                        <div class="me-4 mt-1">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="utenza.id"
                            :checked="documento.visibile_a.includes(utenza.id)"
                            id="flexCheckDefault"
                            @click="setUtenzeString(utenza.id)"
                          />
                        </div>
                        <label class="fw-bold">{{ utenza.utenza }}</label>
                      </div>
                      <Field
                        type="hidden"
                        name="visibile_a"
                        v-model="documento.visibile_a"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="visibile_a"
                      />
                    </div>
                  </div>
                  <br />
                  <div class="row mt-1">
                    <div class="col-md-4">
                      <p class="mb-0 pb-0">Diritti di accesso province:</p>
                      <p class="form-text">
                        Selezionare / deselezionare una o più province con
                        CTRL+click del mouse.
                      </p>
                    </div>
                    <div class="col-md-8 mt-1">
                      <select
                        class="form-select multi-select"
                        size="5"
                        multiple
                        aria-label="size 5 select"
                        @change="setProvinceString"
                      >
                        <option disabled>Tutte le province</option>
                        <option
                          v-for="prov in province"
                          :value="prov.codP"
                          :key="prov.codP"
                          :selected="isProvinceSelected(prov.codP)"
                        >
                          {{ prov.nomeP }}
                        </option>
                      </select>
                    </div>
                    <Field
                      type="hidden"
                      name="province"
                      v-model="documento.province"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="province"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-6"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="$emit('resetIdDoc')"
              >
                Chiudi
              </span>
              <button
                type="submit"
                class="badge bg-secondary rounded blueFit fs-6"
                :disabled="disableButton"
              >
                Salva modifiche
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed, watch, onUpdated } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import { viewDocumento } from "@/requests/documentiBacheca";
import { downloadElement } from "@/requests/downloadFiles";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { deleteDocumento } from "@/requests/documentiBacheca";
//componente documenti
import DropZone from "@/components/components-fit/Drag-and-Drop-File/DropZone.vue";
import FilePreview from "@/components/components-fit/Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";
import PreviewUploadedFile from "@/components/components-fit/Drag-and-Drop-File/PreviewUploadedFile.vue";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "doc-bacheca-detail",
  emits: ["documentoEdited", "resetIdDoc"],
  components: {
    SelectInput,
    Form,
    Field,
    ErrorMessage,
    DropZone,
    FilePreview,
    PreviewUploadedFile,
    Loading,
  },
  props: {
    selectedId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const bacheca_tipo_documenti = computed(() =>
      store.getters.getStateFromName("bacheca_tipo_documenti")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const bacheca_tipo_utenza = computed(() =>
      store.getters.getStateFromName("bacheca_tipo_utenza")
    );
    const keys = ref("btd|ip|btu");
    if (
      !province.value ||
      (province.value && !province.value.length) ||
      !bacheca_tipo_utenza.value ||
      !bacheca_tipo_documenti.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const isLoadingDetail = ref(false);

    const documento = ref({});
    const getDocumento = async (value) => {
      documento.value = {};
      isLoadingDetail.value = true;
      await viewDocumento(value).then((res) => {
        isLoadingDetail.value = false;
        if (res.data && res.data.statusCode === 400) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        } else {
          documento.value = { ...res };
        }
      });
    };

    watch(
      () => props.selectedId,
      async (value, old) => {
        if (value != null) {
          await getDocumento(value);
          file.value = {};
        }
      }
    );

    const downloadingFile = ref(false);
    const downloadDocumento = async () => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(
          documento.value.item_id,
          "bacheca",
          documento.value.fname
        );
        // .then((res) => {
        //   if (res.status != 200) {
        //     alertFailed(
        //       res.data.message
        //         ? res.data.message
        //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        //     );
        //     isLoading.value = false;
        //     return;
        //   }
        // });
        downloadingFile.value = false;
      }
    };

    const schema = yup.object().shape({
      id_tipo: yup.string().required("Obbligatorio"),
      descrizione: yup.string().required("Obbligatorio"),
      titolo: yup.string().required("Obbligatorio"),
      province: yup.string().required("Obbligatorio").nullable(),
      visibile_a: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .test("stringCheck", "Campo Obbligatorio", function (value) {
          if (value === "") {
            return false;
          } else {
            return true;
          }
        }),
    });

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatBacheca.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    let bodyFormData = new FormData();

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      isLoading.value = true;
      if (values.visibile_a[0] != "|") {
        values.visibile_a = "|" + values.visibile_a;
      }
      if (values.province[0] != "|") {
        values.province = "|" + values.province;
      }

      Object.entries(values).map((item) => {
        bodyFormData.append(item[0], item[1]);
      });
      bodyFormData.append("item_id", documento.value.item_id);

      if (file.value.file) {
        bodyFormData.append("file", file.value.file);
        file.value.status = "loading";
      }
      await sendFiles(bodyFormData, globalApi.EDIT_DOCUMENTO_BACHECA).then(
        (res) => {
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          } else {
            file.value.status = true;
            const confirmButtonText = `
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-5"
                data-bs-dismiss="modal"
                data-bs-target="#modal_detail-doc-bacheca"
              >
                Chiudi
              </span>`;
            Swal.fire({
              html: "Documento modificato con successo",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton:
                  "me-3 badge bg-light-danger rounded text-danger fs-5",
              },
              confirmButtonText: confirmButtonText,
              allowOutsideClick: false,
            }).then(() => {
              file.value = {};
              documento.value = {};
              resetForm();
              emit("documentoEdited");
            });
          }
          bodyFormData = new FormData();
          // getDocumento(props.selectedId);
          disableButton.value = false;
          isLoading.value = false;
        }
      );
    };

    const onInvalidSubmit = (v) => {
      alertFailed("Compilare i campi obbligatori");
    };

    const setUtenzeString = (idUtenza) => {
      if (documento.value.visibile_a[0] === "|")
        documento.value.visibile_a = documento.value.visibile_a.substring(
          1,
          documento.value.visibile_a.length
        );
      if (documento.value.visibile_a.includes(idUtenza)) {
        documento.value.visibile_a = documento.value.visibile_a
          .replace(idUtenza + "|", "")
          .trim();
      } else {
        documento.value.visibile_a =
          documento.value.visibile_a + idUtenza + "|";
      }
    };
    const setProvinceString = (event) => {
      documento.value.province = "";
      let selectedValues = [];
      selectedValues = [...event.target.selectedOptions].map((x) => x.value);
      // .filter((x) => x.selected)
      // .map((x) => x.value);
      selectedValues.forEach((idProv) => {
        documento.value.province = documento.value.province + idProv + "|";
      });
    };

    const supportedFormatBacheca = ref(
      ".pdf, .zip, .doc, .ppt, .xls, .docx, .pptx, .xlsx"
    );

    const eliminaFile = (idDoc) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteDocumento(idDoc).then(async (res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              await getDocumento(props.selectedId);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };
    const isProvinceSelected = (idprov) => {
      const arrayProvince = documento.value.province.split("|");
      let isSelected = false;
      arrayProvince.some((element) => {
        if (element === idprov) isSelected = true;
      });
      return isSelected;
    };
    return {
      isProvinceSelected,
      downloadDocumento,
      onSubmit,
      onInputChange,
      schema,
      province,
      bacheca_tipo_utenza,
      bacheca_tipo_documenti,
      file,
      addFile,
      removeFile,
      onInvalidSubmit,
      setUtenzeString,
      setProvinceString,
      documento,
      disableButton,
      isLoading,
      isLoadingDetail,
      supportedFormatBacheca,
      eliminaFile,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
