<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add-doc-bacheca">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiungi comunicazione</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-3">
                    <label class="fw-bold">Tipo comunicazione*</label>

                    <SelectInput
                      :options="bacheca_tipo_documenti"
                      name="id_Tipo"
                    />
                  </div>
                  <div class="col-md-9">
                    <label class="fw-bold">Oggetto*</label>
                    <Field
                      name="titolo"
                      type="text"
                      class="form-control"
                      :disabled="disabled"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="titolo"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold">Sommario comunicazione*</label>
                    <Field
                      name="descrizione"
                      type="text"
                      as="textarea"
                      class="form-control"
                      :disabled="disabled"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="descrizione"
                    />
                  </div>
                </div>
                <div class="row pb-4" v-show="!disabled">
                  <div class="col-md-12">
                    <label class="fw-bold">File*</label>
                  </div>

                  <div class="col-lg-11 d-flex ps-0 pe-xl-11">
                    <div
                      class="image-input image-input-outline ms-2"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    ></div>
                  </div>
                  <DropZone
                    @files-dropped="addFile($event, supportedFormatBacheca)"
                    #default="{ dropZoneActive }"
                  >
                    <label for="file-input-add-bac" style="cursor: pointer">
                      <span v-if="dropZoneActive">
                        <span>Lascia qui il file </span>
                        <span class="smaller">per aggiungerlo</span>
                      </span>
                      <span v-else>
                        <span>Trascina qui il tuo file</span>
                        <span class="smaller">
                          oppure
                          <strong><em>clicca qui</em></strong>
                          per selezionare il file
                        </span>
                      </span>
                    </label>
                    <input
                      type="file"
                      id="file-input-add-bac"
                      style="display: none"
                      :accept="supportedFormatBacheca"
                      @change="onInputChange"
                    />
                    <ul class="ps-0" v-if="file.id">
                      <FilePreview
                        :key="file.id"
                        :file="file"
                        tag="li"
                        @remove="removeFile"
                      />
                    </ul>
                  </DropZone>
                </div>
                <div class="row">
                  <div class="col-lg-11 form-text">
                    Tipi di file concessi: {{ supportedFormatBacheca }}
                  </div>
                </div>

                <hr class="my-10" />

                <div class="row">
                  <div class="col-md-4">
                    <p class="">Diritti di accesso figure* :</p>
                  </div>

                  <div class="col-md-8">
                    <div class="row">
                      <div
                        class="col-md-4 d-flex"
                        v-for="utenza in bacheca_tipo_utenza"
                        :key="utenza.id"
                      >
                        <div class="me-4 mt-1">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="utenza.id"
                            id="flexCheckDefault"
                            @click="setUtenzeString(utenza.id)"
                            :checked="
                              visibile_A
                                ? visibile_A.includes(utenza.id)
                                : false
                            "
                          />
                        </div>
                        <label class="fw-bold">{{ utenza.utenza }}</label>
                      </div>
                      <Field
                        type="hidden"
                        name="visibile_A"
                        v-model="visibile_A"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="visibile_A"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row mt-1">
                  <div class="col-md-4">
                    <p class="mb-0 pb-0">Diritti di accesso province* :</p>
                    <p class="form-text">
                      Selezionare / deselezionare una o più province con
                      CTRL+click del mouse.
                    </p>
                  </div>
                  <div class="col-md-8 mt-1">
                    <select
                      class="form-select multi-select"
                      size="5"
                      multiple
                      aria-label="size 5 select"
                      @change="setProvinceString"
                    >
                      <option disabled>Tutte le province</option>
                      <option
                        v-for="prov in province"
                        :value="prov.codP"
                        :key="prov.codP"
                        :selected="isProvinceSelected(prov.codP)"
                      >
                        {{ prov.nomeP }}
                      </option>
                    </select>
                  </div>
                  <Field type="hidden" name="province" v-model="provinceStr" />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    name="province"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Chiudi
            </span>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Inserisci Comunicazione
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format } from "@/composables/formatDate";

import { useStore } from "vuex";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed, alertSuccess } from "@/composables/swAlert";

//componente documenti
import DropZone from "@/components/components-fit/Drag-and-Drop-File/DropZone.vue";
import FilePreview from "@/components/components-fit/Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
export default {
  name: "add-doc-bacheca",
  emits: ["documentAdded"],
  props: ["societa"],
  components: {
    SelectInput,
    Form,
    Field,
    ErrorMessage,
    DropZone,
    FilePreview,
    Loading,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const bacheca_tipo_documenti = computed(() =>
      store.getters.getStateFromName("bacheca_tipo_documenti")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const bacheca_tipo_utenza = computed(() =>
      store.getters.getStateFromName("bacheca_tipo_utenza")
    );
    const keys = ref("btd|ip|btu");
    if (
      !province.value ||
      (province.value && !province.value.length) ||
      !bacheca_tipo_utenza.value ||
      !bacheca_tipo_documenti.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const schema = yup.object().shape({
      id_Tipo: yup.string().required("Obbligatorio"),
      descrizione: yup.string().required("Obbligatorio"),
      titolo: yup.string().required("Obbligatorio"),
      province: yup.string().required("Obbligatorio").nullable(),
      visibile_A: yup.string().required("Obbligatorio").nullable(),
    });

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatBacheca.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    let bodyFormData = new FormData();

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      isLoading.value = true;
      isLoading.value = true;
      values.visibile_A = "|" + values.visibile_A;
      values.province = "|" + values.province;
      Object.entries(values).map((item) => {
        bodyFormData.append(item[0], item[1]);
      });
      if (file.value.file) {
        bodyFormData.append("file", file.value.file);
        file.value.status = "loading";
      } else {
        alertFailed("Inserire un file!");
        bodyFormData = new FormData();
        disableButton.value = false;
        isLoading.value = false;
        return;
      }
      await sendFiles(bodyFormData, globalApi.AGGIUNGI_DOCUMENTO_BACHECA).then(
        (res) => {
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          } else {
            file.value.status = true;
            alertSuccess(
              "Documento aggiunto con successo",
              "kt_modal_add-doc-bacheca"
            );
            emit("documentAdded");
            //reset form
            visibile_A.value = "";
            provinceStr.value = "";
            file.value = {};
            visibile_A.value = null;
            resetForm();
          }
          bodyFormData = new FormData();
          disableButton.value = false;
          isLoading.value = false;
        }
      );
    };

    const onInvalidSubmit = (v) => {
      alertFailed("Compilare i campi obbligatori");
    };

    const visibile_A = ref("");
    const setUtenzeString = (idUtenza) => {
      if (visibile_A.value && visibile_A.value.includes(idUtenza)) {
        visibile_A.value = visibile_A.value.replace(idUtenza + "|", "");
      } else {
        visibile_A.value = visibile_A.value + idUtenza + "|";
      }
    };
    const provinceStr = ref("");
    const setProvinceString = (event) => {
      provinceStr.value = "";
      const selectedValues = [...event.target.selectedOptions].map(
        (x) => x.value
      );
      selectedValues.forEach((idProv) => {
        provinceStr.value = provinceStr.value + idProv + "|";
      });
    };

    const supportedFormatBacheca = ref(
      ".pdf, .zip, .doc, .ppt, .xls, .docx, .pptx, .xlsx"
    );

    const isProvinceSelected = (idprov) => {
      const arrayProvince = provinceStr.value.split("|");
      let isSelected = false;
      arrayProvince.some((element) => {
        if (element === idprov) isSelected = true;
      });
      return isSelected;
    };

    return {
      isProvinceSelected,
      onSubmit,
      onInvalidSubmit,
      file,
      addFile,
      removeFile,
      onInputChange,
      bacheca_tipo_documenti,
      province,
      bacheca_tipo_utenza,
      visibile_A,
      provinceStr,
      setUtenzeString,
      setProvinceString,
      schema,
      format,
      disableButton,
      isLoading,
      supportedFormatBacheca,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
